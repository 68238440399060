import React from "react";
import { createRoot } from "react-dom/client";
import { ErrorPage } from "@procore/error-pages";

export interface ErrorComponentProps {
   error?: string;
   status?: string;
}
type ContentEnum = {
   [key: string]: { title: string; message: string; steps: string[]; code?: number };
};
const content: ContentEnum = {
   "Company Mismatch": {
      title: "Resource Planning Setup Incomplete",
      message:
         "The Procore Company ID is not properly linked or not set. Please contact us at support@procore.com to connect the two accounts.",
      steps: [],
      code: 401,
   },
   "Unauthorized.": {
      title: "Accounts Not Linked",
      message:
         "The Resource Planning tool helps you easily manage your resources. To start using Resource Planning, contact your Company level admin to link your Procore and LaborChart accounts.",
      steps: [],
   },
   "User Deactivated": {
      title: "User Has Been Deactivated",
      message:
         "Sorry, it looks like the user associated with your email in the Resource Planning people list has been deactivated. Please contact your administrator to reactivate your account.",
      steps: [],
      code: 401,
   },
   "User Not Found": {
      title: "User Access Not Configured Correctly",
      message:
         "It appears you are having trouble accessing the Resource Planning tool. Please contact your company's Procore Administrator and have them check the following:",
      steps: [
         "A matching user must also be created in the Resource Planning's People List",
         "The email address must match the one used in Procore Directory",
         "The user must have a 'Status' of 'Active' in Resource Planning",
         "And the 'Person Type' needs to be 'User' or 'Both' (Assignable Resources only are not granted user access)",
      ],
      code: 401,
   },
   undefined: {
      title: "Something Went Wrong",
      message:
         "There's been a problem loading the Resource Planning Tool. Please contact support@procore.com to resolve the issue.",
      steps: [],
      code: 500,
   },
};

export default ({ error = "Unauthorized" }: ErrorComponentProps) => {
   const container = document.getElementById("error-mount");
   const root = createRoot(container!);

   root.render(
      <ErrorPage
         // @ts-expect-error ContentEnumn needs better typing
         code={content[error].code}
         title={<div style={{ whiteSpace: "nowrap" }}>{content[error].title}</div>}
         message={
            <>
               <span>{content[error].message}</span>
               <ul style={{ listStyle: "inherit", marginTop: "10px", paddingInlineStart: "40px" }}>
                  {content[error].steps.map((step, i) => (
                     <li key={step + i} style={{ whiteSpace: "nowrap" }}>
                        {step}
                     </li>
                  ))}
               </ul>
            </>
         }
         style={{ padding: "0 0 0 0" }}
      />,
   );
};
